<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑医废类型':'新增医废类型'" :visible.sync="show" width="600px" class="selfInputBox">


        <el-upload drag class="" action="/api/item/uploadImage" :headers="Headers" name="image" list-type="picture-card"
            :limit="10" :on-success="handleAvatarSuccess" :on-remove="handleRemove" :before-upload="beforeAvatarUpload"
            :file-list="photoList" :show-file-list="true">

        </el-upload>







        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {

        },
        data() {
            return {
                show: false,

                // 回显图片的路径
                photoList: [],
                // 图片的路径
                dialogImageUrl: "",


            }
        },


        watch: {

            show(value) {
                if (this.show) {

                } else {

                }
            },

        },

        computed: {

            Headers() {
                let token = localStorage.getItem("token");
                return {
                    token
                }
            }

        },

        methods: {
            open() {

                this.show = true
            },

            edit(val) {

                this.show = true

            },



            handleRemove(file, fileList) {
                console.log(file, fileList)
                this.dialogImageUrl = ''
            },
            handleAvatarSuccess(res, file) {

                console.log("res, fileres, fileres, fileres, file", res, file);

                this.dialogImageUrl = res.data
                console.log(this.dialogImageUrl)
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!')
                }
                return /* isJPG && */ isLt2M
            },




            save() {

                // // 新建
                // this.$http.post('/api/item', this.upDatavalue).then((res) => {
                //     console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                //     if (res.code == 200) {

                //         this.show = false
                //         this.$emit('refresh')

                //     }

                //     if (res.code != 200) { //请求错误
                //         this.$message.error(res.msg);

                //     }
                // }).catch((err) => {
                //     console.log(err)
                // });


            },

        }
    }
</script>


<style lang="scss" scoped>
    // .selfInputBox {
    // }

    // /deep/.el-input__suffix {
    //     // border: 1px red solid !important;
    //     // box-sizing: content-box !important;
    //     position: relative;
    //     right: 10px;
    // }

    // /deep/.el-input__prefix {
    //     // border: 1px red solid !important;
    //     // box-sizing: content-box !important;
    //     position: absolute;
    //     left: 20px;
    // }

    // .el-select.el-select--mini,
    // .el-date-editor.el-input,
    // .el-date-editor.el-input__inner {
    //     width: 100%;
    // }
</style>