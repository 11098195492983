<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true">
                <el-form-item>
                    <el-input clearable @keydown.enter.native="search" v-model="searchtitle" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>

                    <el-button type="primary" @click="search()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="addItem">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加会员卡
                    </el-button>

                </el-form-item>
            </el-form>
        </div>




        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable">

            <el-table-column show-overflow-tooltip type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="img" label="医废类型封面" align="center" width="100">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="GLOBAL.BASE_URL+'/uploads' +scope.row.image" v-if="scope.row.image"
                        class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="name" label="医废类型名称" align="left" />

            <el-table-column show-overflow-tooltip prop="state" label="状态" align="center" width="90">

                <template slot-scope="scope">
                    <!-- <el-switch v-model="scope.row.status" active-color="#5BD995" active-text="正常"
                        inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                    </el-switch> -->
                    <el-switch v-model="scope.row.status" :active-value="1" active-color="#5BD995" active-text="正常"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox"
                        style="pointer-events: painted;" @change="switchStatus(scope.row)">
                    </el-switch>
                </template>

            </el-table-column>

            <el-table-column show-overflow-tooltip prop="sort" label="排序" align="left" min-width="70" />

            <el-table-column show-overflow-tooltip prop="note" label="备注" align="left" />



            <el-table-column show-overflow-tooltip label="操作" align="center" width="400">
                <template slot-scope="scope">
                    <div class="operate1">

                        <el-button type="" class="white" @click="">
                            详情
                        </el-button>


                        <el-button type="" class="blue" @click="">
                            编辑
                        </el-button>
                        <el-button type="" class="green" @click="">
                            复制
                        </el-button>
                        <el-button type="" class="dirtyblue" @click="">
                            上移
                        </el-button>
                        <el-button type="" class="dirtybown" @click="">
                            下移
                        </el-button>
                        <el-button type="" class="pink" @click="">
                            删除
                        </el-button>

                    </div>
                </template>
            </el-table-column>

        </el-table>

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />


        <memberType ref="memberType" @refresh="getMedicalWasteTypes" />

        <memberTicket ref="memberTicket" @refresh="getMedicalWasteTypes" />




    </div>
</template>

<script>
    import memberType from './components/memberType'

    import memberTicket from './components/memberTicket'

    import resizeDetector from 'element-resize-detector'

    // import {
    //     mapState,
    //     mapMutations
    // } from 'vuex'

    export default {
        components: {
            memberType,
            memberTicket
        },
        data() {
            return {
                searchtitle: "",
                data: {
                    list: [],
                    page: 1,
                    size: 10,
                    total: 0,
                },
            }
        },

        computed: {
            // ...mapState('m_item', ['MedicalWasteItem']),
        },

        created() {
            // this.getList()
            this.modifyLayout();
        },

        mounted() {
            this.modifyLayout();
            this.getMedicalWasteTypes()
        },

        watch: {
            searchtitle(newValue, oldValue) {
                if (oldValue.length > 0 && newValue.length == 0) {

                    this.search()

                }

            }
        },

        methods: {
            // ...mapMutations('m_item', ['setMedicalWasteItem']),

            getMedicalWasteTypes() {

                this.$http.get('/api/item', {

                    name: this.searchtitle,
                    page_size: this.data.size,
                    page: this.data.page

                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                    if (res.code == 200) {
                        // this.setMedicalWasteItem(res.data.results)
                        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.MedicalWasteItem);

                        this.data.list = res.data.data
                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getMedicalWasteTypes()
            },


            search() {
                this.data.page = 1
                this.getMedicalWasteTypes()
            },



            addItem() {
                // this.$refs.memberType.open()
                this.$refs.memberTicket.open()

            },


            edit(row) {
                console.log(row)

                this.$refs.memberType.edit(row)
                // this.$refs.memberTicket.edit(row)

            },


            switchStatus(row) {

                // console.log(row);

                // return

                let a = row.status == 1 ? 2 : 1

                this.$http.put('/api/item/setStatus/' + row.id, {
                    status: row.status
                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        row.status = a
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        // this.close()
                    }
                }).catch((err) => {
                    console.log(err)
                });


            },


            view(row) {
                console.log(row)
            },

            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {

                        if (action === 'confirm') {

                            this.$http.del('/api/item/' + row.id).then((res) => {

                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.getMedicalWasteTypes()
                                }

                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);


                            }).catch((err) => {
                                console.log(err)
                            });

                        }
                        // if (action === 'confirm') {
                        //     //     instance.confirmButtonLoading = true;
                        //     //     instance.confirmButtonText = '执行中...';
                        //     //     setTimeout(() => {
                        //     //         done();
                        //     //         setTimeout(() => {
                        //     //             instance.confirmButtonLoading = false;
                        //     //         }, 300);
                        //     //     }, 3000);


                        //     // } else {
                        //     done();
                        // }
                    }
                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });
            },






            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;

                    var a = 141 + 60 - 35 + height;
                    if ($(".level_indexI .FanfuTableBox")[0]) {
                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 33 + height;
                    if ($(".level_indexI .el-table")[0]) {
                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }

                });

            }

        },

    }
</script>

<style lang="scss" scoped>
    .operate1 {
        // width: 400px;
        // display: block;

        // border: 1px red solid;
        // box-sizing: border-box;

        .el-button {
            color: #fff;
            border: none;
            padding: 0 20px !important;
        }

        .white {
            color: #606266;
            border: 1px #DCDFE6 solid;
            box-sizing: border-box;
        }

        .blue {
            background-color: #768DFC;
        }

        .green {
            background-color: #5BD995;
        }

        .dirtyblue {
            background-color: #4EBBC8;
        }

        .dirtybown {
            background-color: #B99216;
        }

        .pink {
            background-color: #FF754C;
        }

    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  }

  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini {
    border: 1px red dotted;
    box-sizing: border-box;
  } */


    /* .FanfuTableBox {
        border: 1px red dotted;
        box-sizing: border-box;
    } */
</style>