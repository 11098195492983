<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑会员类型':'新增会员类型'" :visible.sync="show" width="600px"
        class="selfInputBox">

        <el-form ref="form" label-width="80px">


            <el-form-item class="must" label="标题" prop="">
                <el-input v-model="upDatavalue.title" />
            </el-form-item>

            <el-form-item label="描述" prop="">
                <el-input v-model="upDatavalue.description" />
            </el-form-item>


            <el-form-item label="图片" prop="">
                <el-upload drag class="uploadImg" action="/api/renew/uploadImage" :headers="Headers" name="image"
                    list-type="picture-card" :limit="1" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                    :before-upload="beforeAvatarUpload" :file-list="photoList" :show-file-list="true"
                    accept=".jpg,.png">
                    <div class="el-upload__text">
                        <p>将文件拖到此处</p>
                        <p>或<em>点击上传</em></p>
                        <p>只能上传jpg/png文件</p>
                        <p>且不超过500kb</p>
                    </div>
                </el-upload>
            </el-form-item>


            <el-form-item class="must" label="类型">
                <el-select v-model="upDatavalue.type" placeholder="会员类型">
                    <el-option :label="item.name" :value="item.id" v-for="item in timeTypt" :key="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item class="must" label="续费数量" prop="">
                <el-input v-model="upDatavalue.number" />
            </el-form-item>

            <el-form-item class="must" label="价格" prop="">
                <el-input v-model="upDatavalue.money" />
            </el-form-item>

            <!-- <el-form-item label="活动开始时间" prop="">
                <el-input v-model="upDatavalue.start_time" />
            </el-form-item>
            <el-form-item label="活动结束时间" prop="">
                <el-input v-model="upDatavalue.end_time" />
            </el-form-item> -->

            <el-form-item label="活动时间" prop="" style="clear: both">
                <el-date-picker v-model="timeRange" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>



            <el-form-item label="是否启用" prop="">
                <el-switch v-model="upDatavalue.is_public" :active-value="1" active-color="#5BD995" active-text="上架"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="下架" class="switchSelfBox">
                </el-switch>
            </el-form-item>



            <!-- {{scope.row.hospital_type==1?'公立医院':'下属民营'}} -->
            <el-form-item label="医院类型" class="roleListBox" style="width: 100%;">
                <el-radio style="margin-left: 20px;" v-model="upDatavalue.hospital_type" :label="1">公立医院</el-radio>
                <el-radio v-model="upDatavalue.hospital_type" :label="2">下属民营</el-radio>
            </el-form-item>



            <!-- <el-form-item label="是否启用" prop="">
                <el-switch v-model="upDatavalue.status" :active-value="1" active-color="#5BD995" active-text="正常"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                </el-switch>
            </el-form-item>

            <el-form-item label="是否推荐" prop="">
                <el-switch v-model="upDatavalue.status" :active-value="1" active-color="#5BD995" active-text="正常"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                </el-switch>
            </el-form-item> -->


            <!-- <el-form-item label="有效期" prop="" style="clear: both">
                <el-date-picker v-model="upDatavalue.entry_date" type="date" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" placeholder="选择日期">
                </el-date-picker>
            </el-form-item>


            <el-form-item label="开始时间" prop="" style="clear: both">
                <el-date-picker v-model="upDatavalue.entry_date" type="date" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" placeholder="选择日期">
                </el-date-picker>
            </el-form-item>


            <el-form-item label="结束时间" prop="" style="clear: both">
                <el-date-picker v-model="upDatavalue.entry_date" type="date" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" placeholder="选择日期">
                </el-date-picker>
            </el-form-item> -->


        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {

        },
        data() {
            return {
                show: false,

                upDatavalue: {
                    is_public: 1
                },

                // 类型：1年、2月、3日
                timeTypt: [{
                    id: 1,
                    name: '年',
                }, {
                    id: 2,
                    name: '月',
                }, {
                    id: 3,
                    name: '日',
                }, ],

                isEdit: false,


                // 回显图片的路径
                photoList: [],
                // 图片的路径
                dialogImageUrl: "",

                timeRange: '',

            }
        },

        watch: {

            show(value) {
                if (this.show) {

                } else {

                    this.isEdit = false

                    this.upDatavalue = {}

                    this.photoList = []

                    this.dialogImageUrl = ''

                    this.timeRange = ''

                }
            },

        },

        computed: {
            Headers() {
                let token = localStorage.getItem("token");
                return {
                    token
                }
            }

        },

        methods: {
            open() {

                this.upDatavalue = {
                    is_public: 1
                }

                this.show = true
            },

            async edit(val) {

                this.$http.get('/api/renew/' + val.id).then((res) => {

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                    if (res.code == 200) {

                        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        this.upDatavalue = res.data

                        this.dialogImageUrl = this.upDatavalue.img

                        this.timeRange = [String(this.upDatavalue.start_time), String(this.upDatavalue
                            .end_time)]

                        this.photoList = [{
                            url: this.GLOBAL.BASE_URL + this.dialogImageUrl
                        }]

                        this.show = true

                        this.isEdit = true

                    }
                }).catch((err) => {
                    console.log(err)
                });


            },


            save() {

                // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
                // // 回显图片的路径
                // photoList: [],
                // // 图片的路径
                // dialogImageUrl: "",
                // console.log(this.photoList);
                // console.log(this.dialogImageUrl);

                // console.log(this.timeRange);
                // return

                this.upDatavalue.img = this.dialogImageUrl
                this.upDatavalue.start_time = this.timeRange[0] ? this.timeRange[0] : ""
                this.upDatavalue.end_time = this.timeRange[1] ? this.timeRange[1] : ""
                // console.log(this.upDatavalue);


                if (this.isEdit) {

                    // 更新
                    this.$http.put('/api/renew/' + this.upDatavalue.id, this.upDatavalue).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                        if (res.code == 200) {

                            this.show = false
                            this.$emit('refresh')

                        }
                    }).catch((err) => {
                        console.log(err)
                    });


                } else {

                    // 新建
                    this.$http.post('/api/renew', this.upDatavalue).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code == 200) {
                            this.show = false
                            this.$emit('refresh')
                        }
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                }

            },



            handleRemove(file, fileList) {
                console.log(file, fileList)
                this.dialogImageUrl = ''
            },
            handleAvatarSuccess(res, file) {

                console.log("res, fileres, fileres, fileres, file", res, file);

                this.dialogImageUrl = res.data
                console.log(this.dialogImageUrl)
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!')
                }
                return /* isJPG && */ isLt2M
            },

        }
    }
</script>


<style lang="scss" scoped>
    // .selfInputBox {
    // }
    /deep/.el-input__suffix {
        // border: 1px red solid !important;
        // box-sizing: content-box !important;
        position: relative;
        right: 10px;
    }

    /deep/.el-input__prefix {
        // border: 1px red solid !important;
        // box-sizing: content-box !important;
        position: absolute;
        left: 20px;
    }

    .el-select.el-select--mini,
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }

    .uploadImg {
        // border: 1px red solid !important;
        box-sizing: content-box !important;
        position: relative;
        right: -20px;
        width: 148px;
        height: 148px;
        overflow: hidden;

        // line-height: 30px;
        /deep/.el-upload--picture-card {
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
        }

        /deep/.el-upload__text {
            line-height: 30px;
            text-align: center;
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
        }

        /deep/.el-upload-dragger {
            width: 100%;
            height: 100%;
            // width: 148px;
            // height: 148px;
            // line-height: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
</style>